var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("BaseCard", [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _c("h5", { staticClass: "card-category" }, [
                _vm._v(" " + _vm._s(_vm.$tc("user", 2)) + " "),
              ]),
              _c("h2", { staticClass: "card-title" }, [
                _vm._v(" " + _vm._s(_vm.$t("latestLogin")) + " "),
              ]),
            ]),
            _c(
              "div",
              [
                _c("BaseListTable", {
                  attrs: {
                    items: _vm.users,
                    "header-fields": _vm.lastLoginFields,
                    type: "users",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("BaseCard", [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _c("h5", { staticClass: "card-category" }, [
                _vm._v(" " + _vm._s(_vm.$tc("post", 2)) + " "),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("h2", { staticClass: "card-title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("latest")) + " "),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "form-group col-6 text-right" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: { name: "singlePost", params: { id: "new" } },
                        },
                      },
                      [
                        _c(
                          "BaseButton",
                          { attrs: { type: "green", gradient: "" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("addItem", { item: _vm.$tc("post") })
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              [
                _c("BaseListTable", {
                  attrs: {
                    items: _vm.latestPosts,
                    "header-fields": _vm.latestPostFields,
                    type: "posts",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
    _vm.$store.getters["permissions/checkIfUserHasPermission"](
      "memcached.read-statistics"
    )
      ? _c("div", { staticClass: "row cache" }, [
          _c("div", { staticClass: "col-12" }, [_c("CacheStatistics")], 1),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }